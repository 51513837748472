import * as actionTypes from '../actions/actionType';
import { updateObject } from '../utility';
import { message } from 'antd';
import i18next from 'i18next';


const LOADED_DATA_EXPIRE_TIME = 60;

const initialState = {
  contact_error: null,
  contact_loading: false,
  contact_data: null,
  note_error: null,
  dnc_error: null,
  disposition_error: null,
  expiration_date: null,
  secs_since_last_call: false,
  wrapup_notification_sent: false
}

// load Contact
const loadContact = (state, action) => {
  return updateObject(state, {
    contact_loading: true,
    // set error back
    contact_error: null,
    note_error: null,
    callback_error: null,
    dnc_error: null,
    disposition_error: null
  });
}

const resetContact = (state, action) => {
  // Back to initialState
  return updateObject(state, initialState);
}

const loadDoneContact = (state, action) => {
  if (state.contact_data && state.contact_data.subscriber_id) {
    // console.log('current subscriber_id:', state.contact_data.subscriber_id);
    // console.log('new subscriber_id:', action.contact_data.subscriber_id);
    if (state.contact_data.subscriber_id === action.contact_data.subscriber_id) {
      // Don't update the state
      return updateObject(state, {
      });
    } else {
      // New Subscriber ID
      message.success(i18next.t("New contact data loaded!"), 1);
      return updateObject(state, {
        contact_data: action.contact_data,
        contact_error: null,
        contact_loading: false,
        expiration_date: null,
        secs_since_last_call: 0,
        wrapup_notification_sent: false
      });
    }
  } else {
    message.success(i18next.t("Contact data loaded!"), 1);
    // state.contact_data is null
    return updateObject(state, {
      contact_data: action.contact_data,
      contact_error: null,
      contact_loading: false,
      expiration_date: null,
      secs_since_last_call: 0,
      wrapup_notification_sent: false
    });
  }

}

const loadFailedContact = (state, action) => {
  let expiration_date = null;
  let contact_data = state.contact_data;
  let wrapup_notification_sent = state.wrapup_notification_sent;
  if (!state.expiration_date) {
    expiration_date = new Date(new Date().getTime() + LOADED_DATA_EXPIRE_TIME * 1000);
  } else {
    expiration_date = state.expiration_date;
    if (expiration_date <= new Date()) {
      contact_data = null;
    }
    if (!wrapup_notification_sent && contact_data) {
      wrapup_notification_sent = true;
      message.warning(i18next.t("Hurry up and fill in the contact data!"), 2);
    }
  }
  return updateObject(state, {
    contact_error: action.error,
    contact_loading: false,
    contact_data: contact_data,
    expiration_date: expiration_date,
    secs_since_last_call: action.secs_since_last_call,
    wrapup_notification_sent: wrapup_notification_sent
  });
}

// TODO: Missing reducer for DNC, Disposition, Add Note

const addNoteStarted = (state, action) => {
  return updateObject(state, {
    note_error: null
  });
}

const addNoteDone = (state, action) => {
  return updateObject(state, {
    note_error: true
  });
}

const addNoteFailed = (state, action) => {
  return updateObject(state, {
    note_error: false
  });
}


const addCallbackStarted = (state, action) => {
  return updateObject(state, {
    callback_error: null
  });
}

const addCallbackDone = (state, action) => {
  return updateObject(state, {
    callback_error: true
  });
}

const addCallbackFailed = (state, action) => {
  return updateObject(state, {
    callback_error: false
  });
}

const updateCallbackStarted = (state, action) => {
  return updateObject(state, {
    callback_error: null
  });
}

const updateCallbackDone = (state, action) => {
  return updateObject(state, {
    callback_error: true
  });
}

const updateCallbackFailed = (state, action) => {
  return updateObject(state, {
    callback_error: false
  });
}


// -------------------------
// REDUCER
// -------------------------
const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_CONTACT_STARTED: return loadContact(state, action);
    case actionTypes.LOAD_CONTACT_DONE: return loadDoneContact(state, action);
    case actionTypes.LOAD_CONTACT_FAILED: return loadFailedContact(state, action);
    case actionTypes.RESET_CONTACT: return resetContact(state, action);
    // Add NOTE
    case actionTypes.ADD_NOTE_STARTED: return addNoteStarted(state, action);
    case actionTypes.ADD_NOTE_DONE: return addNoteDone(state, action);
    case actionTypes.ADD_NOTE_FAILED: return addNoteFailed(state, action);
    // Add Callback
    case actionTypes.ADD_CALLBACK_STARTED: return addCallbackStarted(state, action);
    case actionTypes.ADD_CALLBACK_DONE: return addCallbackDone(state, action);
    case actionTypes.ADD_CALLBACK_FAILED: return addCallbackFailed(state, action);
    // Update Callback
    case actionTypes.UPDATE_CALLBACK_STARTED: return updateCallbackStarted(state, action);
    case actionTypes.UPDATE_CALLBACK_DONE: return updateCallbackDone(state, action);
    case actionTypes.UPDATE_CALLBACK_FAILED: return updateCallbackFailed(state, action);
    default:
      return state;
  }
};

export default contactReducer;
